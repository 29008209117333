/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';


@import '~bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import 'ngx-toastr/toastr';

body {
  width: 100vw;
  height: 100vh;
  font-family: 'open sans';
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  width: 0px;
  background: darkgray;
}

/*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}*/

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}

.loader {
  position: absolute;
  margin: auto;
  top: -115px;
  bottom: 0;
  left: -40px;
  right: 0;
  width: 6.250em;
  height: 6.250em;
  animation: rotate5123 2.4s linear infinite;
}

.white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}

.dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}

.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #FF4444;
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #FFBB33;
  animation: dotsX 2.4s linear infinite;
}

.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #99CC00;
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #33B5E5;
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate5123 {
  0% {
      transform: rotate( 0 );
  }

  10% {
      width: 6.250em;
      height: 6.250em;
  }

  66% {
      width: 2.4em;
      height: 2.4em;
  }

  100% {
      transform: rotate(360deg);
      width: 6.250em;
      height: 6.250em;
  }
}

@keyframes dotsY {
  66% {
      opacity: .1;
      width: 2.4em;
  }

  77% {
      opacity: 1;
      width: 0;
  }
}

@keyframes dotsX {
  66% {
      opacity: .1;
      height: 2.4em;
  }

  77% {
      opacity: 1;
      height: 0;
  }
}

@keyframes flash {
  33% {
      opacity: 0;
      border-radius: 0%;
  }

  55% {
      opacity: .6;
      border-radius: 100%;
  }

  66% {
      opacity: 0;
  }
}
